<template>
  <div :class="['kft-heading']">
    <slot name="title">
      <component
        :is="useHTag ? `h${level}` : `div`"
        :data-test-id="testId || title"
        :class="twMerge(`text-h${level}`, hClass)"
        :style="titleColor ? `color: ${titleColor}!important ` : null"
        class="kft-heading__title"
      >
        {{ title }}
      </component>
    </slot>
    <slot name="description">
      <div
        v-if="description"
        :class="dClass"
        class="kft-heading__description"
        v-html="description"
      />
    </slot>
  </div>
</template>
<script>
import { twMerge } from 'tailwind-merge'

export default {
  name: 'Heading',
  props: {
    hClass: {
      default: '',
      type: String,
    },

    dClass: {
      default: '',
      type: String,
    },
    /**
     * Heading level
     */
    level: {
      type: Number,
      default: 2,
    },

    /**
     * Heading title
     */
    title: {
      type: String,
      default: '',
    },
    testId: {
      type: String,
      default: '',
    },

    /**
     * Heading title color
     */
    titleColor: {
      type: String,
      default: '',
    },

    /**
     * Heading description
     */
    description: {
      type: String,
      default: '',
    },

    /**
     * Should the heading be rendered as a heading tag?
     */
    useHTag: {
      type: Boolean,
      default: true,
    },
  },
  methods: { twMerge },
  hasDescription(descriptionProp, slots) {
    return Boolean(descriptionProp) || (slots && slots().description)
  },
}
</script>
<style lang="postcss">
@import '~/assets/css/heading.css';
</style>
